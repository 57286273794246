import React, {memo, useEffect} from 'react'
import {navigation, navigationDropdown} from '@common/layout/layout.constants'
import Link from 'next/link'
import {useTypedSelector} from '@common/hooks/useTypedSelector'
import {EPage, ITranslation} from '@common/types'
import {settings} from '@common/settings'
import {useRouter} from 'next/router'
import Icon from '@common/components/common/Icon'
import {useTranslation} from '@common/hooks/useTranslation'
import {useLocale} from '@common/hooks/useLocale'
import {useActions} from '@common/hooks/useActions'
import {useStateRef} from '@common/hooks/useStateRef'
import {debounce} from '@common/utils/debounce'
import Dropdown from '@components/common/Dropdown'

const Header = () => {
  const {page = '', draftMode, noEng = false, settings: appSettings} = useTypedSelector(state => state.app)
  const actions = useActions()
  const router = useRouter()
  const [isMobileNavActive, setIsMobileNavActive, isMobileNavRef] = useStateRef<boolean>(false)
  const locale = useLocale()
  const t = useTranslation('main') as ITranslation['main']

  const onResize = () => {
    if (window.innerWidth >= 1024 && isMobileNavRef.current) {
      setIsMobileNavActive(false)
    }
  }

  useEffect(() => {
    const onResizeDebounced = debounce(onResize, 100)
    window.addEventListener('resize', onResizeDebounced)
    return () => window.removeEventListener('resize', onResizeDebounced)
  }, [])

  const changeLanguage = () => {
    router.push(router.asPath, router.asPath, {locale: locale === 'ru' ? 'en' : 'ru'})
  }

  return (
    <header className="header" id="header">
      <div className="container">
        <div className="header__content">
          {page === EPage.HOME ? (
            <span className={`header__logo link ${isMobileNavActive ? 'is-menu' : ''}`} data-header-logo="Переход на главную">
              {settings.project.name}
            </span>
          ) : (
            <Link className={`header__logo link ${isMobileNavActive ? 'is-menu' : ''}`} href="/">
              {settings.project.name}
            </Link>
          )}

          <button
            className={`header__sandwich sandwich ${isMobileNavActive ? 'is-active' : ''}`}
            type="button"
            aria-label="Переключатель отображения меню"
            onClick={() => setIsMobileNavActive(prevState => !prevState)}
          >
            <span className="sandwich__menu" />
          </button>

          <nav className={`main-nav ${isMobileNavActive ? 'is-active' : ''}`}>
            <ul className="main-nav__list">
              {navigation
                .filter(n => (locale === 'en' ? n.textEn : n.text))
                // Фильтруем поля нужные только в мобильном меню
                .filter(n => (!n.onlyMobile || isMobileNavActive)) 
                .map((nav, index) => {
                  const isActive = nav.page === page
                  const buttonClasses = [
                    'main-nav__link',
                    'link',
                    'link--violet',
                    appSettings?.formModal ? `link--${appSettings?.formModal?.button?.type ?? ''}` : ''
                  ].join(' ')

                  return (
                    <li
                      key={nav.page ?? 'custom'}
                      className="main-nav__item"
                      data-nav-item={nav.data}
                      style={{transitionDelay: `${isMobileNavActive ? 0.2 * (index + 1) + 0.1 : 0}s`}}
                    >
                      {nav.page === EPage.CUSTOM_HEADER ? (
                        <button className={buttonClasses} onClick={() => actions.setApp({modalForm: {open: true, form: 'header'}})}>
                          {appSettings?.formModal?.button?.title ?? (locale === 'en' ? nav.textEn : nav.text)}
                        </button>
                      ) : (
                        <>
                          {isActive ? (
                            <span className={`main-nav__link link ${nav.radius ?? ''} is-active`}>
                              {locale === 'en' ? nav.textEn : nav.text}
                            </span>
                          ) : (
                            <Link className={`main-nav__link link ${nav.radius ?? ''}`} href={nav.href ?? '#'}>
                              {locale === 'en' ? nav.textEn : nav.text}
                            </Link>
                          )}
                        </>
                      )}
                    </li>
                  )
                })}
            </ul>

            <div className="main-nav__buttons">
              <button className="main-nav__buttons-request" type="button" data-form-open="">
                <span className="main-nav__buttons-text">{t.requestTitle}</span>
              </button>
              <a
                className="main-nav__buttons-link"
                href="https://t.me/chat_with_mobileup"
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label="Ссылка на телеграм"
              >
                <Icon width={36} height={36} name="icon-telegram" />
              </a>
            </div>
          </nav>

          {!noEng && (
            <a className={`header__language link ${!isMobileNavActive ? 'dynamic-adaptive' : ''}`} onClick={changeLanguage}>
              {locale === 'ru' ? 'Eng' : 'Ru'}
            </a>
          )}
          <div className="header__dropdown--desktop">
            <Dropdown itemList={navigationDropdown} />
          </div>
        </div>
      </div>

      {draftMode && <div className="draft-mode-tile">Предпросмотр</div>}
    </header>
  )
}

export default memo(Header)
